<template>
    <MainLayout>
        <div v-if="!$store.state.loading">
            <SignIn @signIn='onActive' v-if="active===enums.LOGIN"/>
            <ForgotPassword @forgotPassword='onActive' @sendEmail='emailForgotPassword' @openInfoModal="openInfoModal" v-if="active===enums.FORGOT_PASSWORD"/>
            <SendLinkEmailForgotPassword @signIn='onActive' :email="email" v-if="active===enums.SEND_EMAIL_FORGOT_PASSWORD"/>
        </div>
        <div class="modal" id="infoModal" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            If an email account is registered with us you will be receiving reset instructions in your inbox
                        </h5>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </MainLayout>
</template>

<script>
import SignIn from "../components/SignIn";
import ForgotPassword from "../components/ForgotPassword";
import MainLayout from "../layouts/MainLayout";
import LoginEnum from "../enums/LoginEnum";
import SendLinkEmailForgotPassword from "../components/SendLinkEmailForgotPassword";
import {mapMutations} from 'vuex';
import { Modal } from 'bootstrap';

export default {
    name: 'Login',
    components: {
        MainLayout,
        SignIn,
        ForgotPassword,
        SendLinkEmailForgotPassword,
    },
    data() {
        return {
            active: LoginEnum.LOGIN,
            email: null,
            infoModal: null,
        }
    },
    destroyed() {
        if (this.infoModal) {
            this.infoModal.dispose();
        }
    },
    mounted() {
        if(this.$route?.query?.token) {
            console.log(this.$route?.query?.token);
            this.autologin();
        } else {
            this.isLogin();
        }
        this.infoModal = new Modal(document.getElementById('infoModal'));
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
            'setUser',
            'setLogged',
        ]),
        openInfoModal() {
            this.infoModal.show();
        },
        autologin() {
            this.showLoader();
            localStorage.setItem('strobeart_jwt', this.$route?.query?.token);
            this.setLogged(true);
            this.$http.getAuth(`${this.$http.apiUrl()}user`)
                .then( response => {
                    localStorage.setItem('strobeart_user', JSON.stringify(response?.data?.data));
                    this.setUser(JSON.stringify(response?.data?.data) || {});
                    this.$router.push({to: '/', name: "Home"}).then();
                })
                .finally(() => this.hideLoader());
        },
        isLogin() {
           if (localStorage.getItem('strobeart_jwt')&&localStorage.getItem('strobeart_user')) {
               this.$router.push({to: '/', name: "Home"}).then();
           }
        },
        onActive(data) {
            this.active = data;
        },
        emailForgotPassword(data) {
            this.email = data;
        }
    },
    computed: {
        enums() {
            return {
                [LoginEnum.LOGIN]: LoginEnum.LOGIN,
                [LoginEnum.FORGOT_PASSWORD]: LoginEnum.FORGOT_PASSWORD,
                [LoginEnum.SEND_EMAIL_FORGOT_PASSWORD]:LoginEnum.SEND_EMAIL_FORGOT_PASSWORD
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.btn_modal_t1 {
    border: 0.5px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    background: white;
    height: 47px;
    width: 148px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #494949;
}
</style>
