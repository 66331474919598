<template>
    <div class="item_sign_in">
        <div class="sign_in">
            <div class="app_logo">
                <img class="" src="@/assets/icons/Logo_circle.png" alt="icon-approved.svg"/>
            </div>
            <div class="montserrat form_sign_in mb-4">
                <div class="login">Login</div>
                <div class="mb-4">
                    <input  type="email" autocomplete="off" v-model="email" @keypress="keypressEmail"
                            :class="{ 'is-invalid': email_is_valid }" class="form-control" id="emailSignUp"
                            placeholder="e-mail">
                    <div id="emailSignUpFeedback" class="invalid-feedback">{{ email_is_valid }}</div>
                </div>
                <div class="">
                    <input type="password" @keypress="keypressPassword" :class="{ 'is-invalid': password_is_valid }" v-model="password" class="form-control" placeholder="password">
                    <div class="invalid-feedback">{{ password_is_valid }}</div>
                </div>
                <div @click="forgotPassword" class="mobile_link style_link justify-content-end">forgot password</div>
                <div class="desc_link_form">
                    <div>
                        <router-link class="style_link" :to="{ name: 'Registration' }">I don’t have an account yet</router-link>
                    </div>
                    <div @click="forgotPassword" class="style_link">Forgot Password</div>
                </div>
            </div>
            <div class="mb-4 box_btn">
                <div class="btn-c2 mb-4" :class="{ 'not-active-btn':!active_btn}" @click="signIn">Sign in</div>
                <div class="mobile_link justify-content-center pt-28 mb-3" >
                    <router-link class="style_link" :to="{ name: 'Registration' }">I don’t have an account yet</router-link>
                </div>
                <div class="sign-in__google" @click="loginWithProvider('google')">
                    <div class="sign-in__google-icon-block">
                        <img src="@/assets/icons/Google__G__Logo.svg.png" alt="icon-approved.svg" class="sign-in__google-icon">
                    </div>
                    <div class="sign-in__google-text">Sign in with Google</div>
                </div>
                <div class="mb-4 btn-c3 d-flex align-items-center" @click="loginWithApple()">
                    <div class="me-4">
                        <img src="@/assets/icons/apple_logo_black.svg" alt="icon-approved.svg"/>
                    </div>
                    <div class="text">Continue with Apple</div>
                </div>
                <div class="mb-4 btn-c1 d-flex align-items-center" @click="loginWithProvider('facebook')">
                    <div class="me-4">
                        <img src="@/assets/icons/Facebook_logo.png" alt="icon-approved.svg" class="sign-in__google-icon"/>
                    </div>
                    <div class="text">Sign in with Facebook</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations} from 'vuex';
import {errorMessage} from "../services/messages";
import {VueTelInput} from 'vue-tel-input';
import LoginEnum from "../enums/LoginEnum";
import helpFunctions from "../helpFunctions";
import ('../css/custom.css');

export default {
    name: "SignIn",
    components: {
        VueTelInput,
    },
    data() {
        return {
            email: '',
            password: null,
            valid_email: false,
            error_valid: false,
            email_is_valid: false,
            password_is_valid: false,
        };
    },
    created: function () {
        window.addEventListener('keyup', this.keyupMethod);
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.keyupMethod);
    },
    computed: {
        active_btn() {
            return (this.email && this.password);
        }
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
            'setUser',
            'setLogged',
        ]),
        keyupMethod(e) {
            if (e.keyCode === 13) {
                this.signIn();
            }
        },
        forgotPassword() {
            this.$emit('signIn', LoginEnum.FORGOT_PASSWORD);
        },
        keypressEmail() {
            this.email_is_valid = false;
        },
        keypressPassword() {
            this.password_is_valid = false;
        },
        async signIn() {
            if (this.formValidate()) {
                return
            }
            try {
                this.showLoader();
                const response = await this.$http.post(`${this.$http.apiUrl()}login`, {
                    'email': this.email,
                    'password':this.password
                });
                if (response.data.data.result) {
                    localStorage.setItem('strobeart_confirm_email', this.email);
                    localStorage.setItem('strobeart_confirm_email_pass', this.password);
                    await this.$router.push({ name: "ConfirmEmail" });
                } else {
                    if (response && response.data && response.data.data && response.data.data.token) {
                        const token = response.data.data.token;
                        localStorage.setItem('strobeart_jwt', token);
                        this.setLogged(true);
                        if(response.data.data.user) {
                            localStorage.setItem('strobeart_user', JSON.stringify(response.data.data.user));
                            this.setUser(response?.data?.data?.user || {});
                        }
                    }
                    this.$router.push({to: '/', name: "Home"}).then();
                }
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        clearValid() {
            this.valid_email = false;
            this.error_valid = false;
            this.email_is_valid = false;
            this.password_is_valid = false;
        },
        formValidate() {
            let error_form =  false
            this.clearValid();
            this.valid_email = helpFunctions.isValidEmail(this.email);
            if (!this.email) {
                this.email_is_valid = 'This field is required';
                error_form = true
            }
            if (this.email && this.valid_email) {
                this.email_is_valid = 'Please enter a valid email address';
                error_form = true;
            }
            if (!this.password) {
                this.password_is_valid = 'This field is required';
                error_form = true;
            }
            if (this.password < 8) {
                this.password_is_valid = 'Password needs to be at least 8 characters';
                error_form = true;
            }
            return  error_form;
        },
        async loginWithProvider(provider) {
            try {
                this.showLoader();
                const response = await this.$http.get(`${this.$http.apiUrl()}auth/`+provider);
                window.location.href = response?.data?.data + '&config_id=' + process.env.VUE_APP_CONFIG_ID;
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        async loginWithApple() {
            try {
                this.showLoader();
                const response = await this.$http.get(`${this.$http.apiUrl()}apple-login`);
                window.location.href = response?.data?.data;
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
    }
}
</script>

<style lang="scss" scoped>
.sign-in__google {
    display: flex;
    align-items: center;
    background: white;
    height: 50px;
    margin-bottom: 15px;
    border-left: 1px solid white;
    cursor: pointer;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

    &-icon {
        height: 18px;
        width: 18px;

        &-block {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px;
            background-color: white;
            border-radius: 1px;
            height: 48px;
        }
    }
    &-text {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 16px;
        color: #757575;

        height: 100%;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.login {
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 44px;
    color: black;
    text-align: center;
    padding-bottom: 43px;
}
.sign_in {
    // height: 100vh;
    margin-bottom: 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.montserrat .form-control {
    padding: 15px 40px;
}
.app_logo {
    display: none;
    padding-top: 80px;
    padding-bottom: 25px;
}
hr {
    color: #494949;
    opacity: unset;
    text-align: center;
    margin: 51px 80px 68px 80px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
.btn-c1 {
    .text {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 16px;
        color: white;

        height: 100%;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    background: #0074ee;
    box-sizing: border-box;
    //border-radius: 10px;
    padding: 15px 17px;
    cursor: pointer;
}
.btn-c3 {
    .text {
        opacity: 0.5;
    }
    > div, img {
        color: black;
        background: white;
    }

    background: white;
    border: 0.5px solid #494949;
    border-radius: 0;
    box-sizing: border-box;
    padding: 15px 17px;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
}
.error_valid {
    color: red;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
}
.item_sign_in {
    display: flex;
    justify-content: center;
    margin-top: 179px;
}
.montserrat {
    width: 550px;
}
.desc_link_form {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    align-items: center;
}
.style_link {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: black;
    opacity: 0.65;
    cursor: pointer;
    text-decoration-line: underline;
}
.box_btn {
    display: grid;
    justify-content: center;
    padding-top: 68px;
    padding-bottom: 100px;
}
.mobile_link {
    display: none;
}
@media only screen and (max-width: 992px) {
    .mobile_link {
        display: flex;
        width: 100%;
        padding-top: 12px;
    }
    .login {
        display: none;
    }
    .item_sign_in {
        margin-top: 0px;
        display: inherit;
    }
    .montserrat {
        width: 100%;
    }
    .form_sign_in {
        padding: 0 45px 0 45px;
    }
    hr {
        margin: 39px 80px 32px 80px;
    }
    .app_logo {
        display: block;
    }
    .app_logo img {
        width: 49px;
        height: 49px;
    }
    .desc_link_form {
        display: none;
    }
    .box_btn {
        padding-top: 10px;
        padding-bottom: 40px;
    }
    .sign_in {
        //height: 100vh;
    }
}
</style>
